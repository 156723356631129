var jquery = require("jquery");
$ = window.$ = window.jQuery = jquery; // notice the definition of global variables here

const Handlebars = require("handlebars");
const Moment = require('moment');

const luxon = require('luxon');
const DateTime = luxon.DateTime;

import {TabulatorFull as Tabulator} from 'tabulator-tables';

// Add the Firebase services that you want to use
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/analytics";
import "firebase/database";
import "firebase/firestore";

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

//
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

//
if(window.document.documentMode){
    document.getElementById('main-preloader').innerHTML = '<div class="col s8 offset-s2"><h3>This browser is not supported. Please use Apple Safari, Mozilla Firefox, Microsoft Edge or Google Chrome</h3></div>';
}
if (window.location.href.indexOf("http://localhost") === -1){
    window.console.log = function(){
        console.info('© 2022, CMEfy @ SCAD.Ventures');
        window.console.log = function() {
            return false;
        }
    }
}

!(function() {
    // MIJI JSKJM
    var ui;
    var CURR_CMEfy_InstancePayload;
    var CURR_UserProfile;
    var CURR_UserAccountState;
    var tfFormEmbed_A, tfFormEmbed_B, tfFormEmbed_C;
    var tfFormEmbed_PaidLink;
    var tfFormMicroGrandRounds; var MGR_formAllowed = false;
    var tfFormInstantRound;
    var tfFormInstantContext;
    var tfFormEmbed_Onboarding;
    var tfFormEmbed_ProfileBetaFeatures;

    var PRE_CONTEXT_App = false;
    var REDIR_URL = false;
    var SHOW_LinkCreator = false;
    var HIDE_HelpBeacon = false;

    var H_cmefiedItemTemplate, H_cmefiedItemActionTemplate, H_miReflectionTemplate;

    $(document).ready((et)=>{
        $('#main-preloader').show();
        $('#cmefied-intro-view').hide();
        $('#clinician-starter-tier-p').hide();
        //
        H_cmefiedItemTemplate = Handlebars.compile(document.querySelector('#cmefied_item').innerHTML);
        H_cmefiedItemActionTemplate = Handlebars.compile(document.querySelector('#cmefied_item_actions').innerHTML);
        H_miReflectionTemplate = Handlebars.compile(document.querySelector('#mi_nudge_anonymous_reflections').innerHTML);
        //
        $('.auth-view').hide();
        $('.unauth-view').hide();
        InitializeUIElements();
        if (getUrlParameter('toast')){
            M.Toast.dismissAll();
            M.toast({html:getUrlParameter('toast')});
        }
        AUTH_FirebaseInitAndAuthCheck();
        //
    });

    var UI_REF_LoginModal,
        UI_REF_ProfileModal,
        UI_REF_MomentEditorModal,
        UI_REF_MomentInsightsModal,
        UI_REF_MicroGrandRoundModal,
        UI_REF_InstantContextModal,
        UI_REF_WherebyEmbedModal,
        UI_REF_LinkEmbedViewModal,
        UI_REF_LearnerInfoModal,
        UI_REF_LinkDetailsBottomSheetModal,
        UI_REF_Payouts_Modal,
        UI_REF_Settings_Modal,
        UI_REF_Upsell_Modal;

    var HELP_Timer = false;
    function InitializeUIElements(){
        //
        $(window).scroll(function() {
            console.log("scrolled");
            Beacon_initAndShowWithDelay();
            if (window.scrollY < 45){
                $('.cmefy-header-bar').removeClass('shadow');
            }else{
                $('.cmefy-header-bar').addClass('shadow');
            }
        });
        //
        M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
        //
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
        //
        M.Collapsible.init(document.querySelectorAll('.collapsible'));
        // For first time users :) after a delay
        M.TapTarget.init(document.querySelectorAll('.tap-target'));
        //
        M.CharacterCounter.init(document.querySelector('#short_bio'));
        M.textareaAutoResize($('#short_bio'));
        M.updateTextFields();
        //
        M.Carousel.init(document.querySelectorAll('.carousel'),{
            duration:0,
            dist: 0,
            indicators: true
        });
        //
        UI_REF_Upsell_Modal = M.Modal.init(document.querySelector('#upsellModal'), {
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_LinkDetailsBottomSheetModal = M.Modal.init(document.querySelector('#linkDetailsModal'),{
            dismissible: true,
            opacity: 0.81,
        });
        //
        UI_REF_LoginModal = M.Modal.init(document.querySelector('#loginModal'), {
            dismissible: true,
            opacity: 0.81,
            startingTop: '1%',
            endingTop: '5%'
        });
        //
        UI_REF_Payouts_Modal = M.Modal.init(document.querySelector('#profile_payoutsModal'), {
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_Settings_Modal = M.Modal.init(document.querySelector('#profile_menuModal'), {
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_ProfileModal = M.Modal.init(document.querySelector('#profileModal'), {
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_LinkEmbedViewModal = M.Modal.init(document.querySelector('#linkEmbedInstructionsModal'),{
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_MomentEditorModal = M.Modal.init(document.querySelector('#cmefyLinkEditor'),{
            dismissible: true,
            opacity: 0.81
        })
        //
        UI_REF_MomentInsightsModal = M.Modal.init(document.querySelector('#momentInsightsModal'),{
            dismissible: true,
            opacity: 0.81
        });
        //
        UI_REF_MicroGrandRoundModal = M.Modal.init(document.querySelector('#microGrandRoundsModal'),{
            dismissible: true,
            opacity: 0.90
        });
        //
        UI_REF_InstantContextModal = M.Modal.init(document.querySelector('#instantContextModal'),{
            dismissible: true,
            opacity: 0.90
        });
        //
        UI_REF_LearnerInfoModal = M.Modal.init(document.querySelector('#learnerInfoModal'),{
            dismissible: true,
            opacity: 0.90
        });
        //
        UI_REF_WherebyEmbedModal = M.Modal.init(document.querySelector('#mgrEmbedModal'), {
            startingTop: '0%',
            dismissible: false,
            opacity: 1.0,
            onOpenEnd:()=>{
            },
            onCloseEnd:()=>{
                $('#whereby_iframe_holder').empty();//
            }
        });
        // we need a listener on window closing
        window.onbeforeunload = ()=>{
            firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).update({is_current_micrograndround_active:"off"}).then(()=>{
            }).catch(ex=>{});
        }
        //
        M.Modal.init(document.querySelector('#faqModal'), {
            dismissible: true,
            opacity: 0.63,
            startingTop: '5%',
            endingTop: '5%'
        });
        //
        $('.launchOnboarding_btn').on('click',()=>{startOnboarding()});
        $('.launchLogin_btn').on('click',()=>{UI_REF_LoginModal.open()});
        //
        M.FloatingActionButton.init(document.querySelector('.fixed-action-btn'),{
            toolbarEnabled:false,
            hoverEnabled:false,
            direction: 'left'
        });
        M.FormSelect.init(document.querySelectorAll('select'), {});
        //
        M.Tabs.init(document.querySelectorAll('.tabs'), {
        });
        //
        M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'));
        //
        M.Sidenav.init(document.querySelectorAll('.sidenav'),{
            draggable: false
        })

    }

    // TASK MANAGER
    function UI_BindBtnTasks_Clicks(){
        $(document).on('click','.ui-action-btn', function(){
            return performUITask($(this).data("task"));
        });

        $(document).on('click', '.copy-contents-btn', function(em){
            console.log(this.innerHTML);
            const el = document.createElement('textarea');
            el.value = this.innerHTML;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            //
            var linkCopied_v2_5 = `<span>✅&nbsp;CMEfy link copied | Please don't forget to use the standard messaging guidelines when sharing it</span><a class="btn-flat toast-action" href="${process.env.STANDARD_CMEFY_GUIDELINES}" target="_blank">Review</a>`
            linkCopied_v2_5 = `<span>✅&nbsp;CMEfy link copied!`
            //
            M.toast({html:linkCopied_v2_5, displayLength:8100});            
        });

        $(document).on('change', '#display_name', (e)=>{
            if (!CURR_UserProfile.cmefy_handle){
                var handledName = $('#display_name').val().trim().split(" ").join("-").split("'").join("").split(".").join("").split("(").join("_").split(")").join("_").toLowerCase();
                // we must add part of the uid to the handled name to avoid collisions
                checkAndSanitizeHandle(handledName, sanitizedHandle=>{
                    handledName = sanitizedHandle;
                    $('#catalyst_handle').val(handledName);
                    $('#public_profile_url').val(`https://cmefy.com/u/${handledName}`);
                    M.updateTextFields();
                });
            }
        })

        $(document).on('change', '#cmefied-tabular-contextual_grouper', (e)=>{
            if($('#cmefied-tabular-contextual_grouper').is(":checked")){
                UI_Table_ToggleGroupByContext(true);
            }else{
                UI_Table_ToggleGroupByContext(false);
            }
        });

        $(document).on('change', '#cmefied-tabular-filter_toggle', (e)=>{
            if($('#cmefied-tabular-filter_toggle').is(":checked")){
                UI_Table_ToggleSearchFilter(true);
            }else{
                UI_Table_ToggleSearchFilter(false);
            }
        })
    }

    function checkAndSanitizeHandle(handle, callback){
        $.ajax({
            url: `/cmefy-handle-ping?user_slug=${handle}`,
            dataType: "json",
            success: (foundPacket) => {
                if (foundPacket.exists === 0){
                    return callback(handle);
                }else{
                    // sanitize the handle
                    return callback(handle + "-"+ firebase.auth().currentUser.uid.substr(0,5));
                }
            },
            error: function(err){
                ERROR_Alerter("Error in confirming unique handle name. Please contact concierge@cmefy.com");
                // sanitize the handle
                return callback(handle + "-"+ firebase.auth().currentUser.uid.substr(0,5));
            }
        });
    }

    function startOnboarding(){
        tfFormEmbed_Onboarding = createPopup(process.env.TYPEFORM_CMEFY_ONBOARDING, {
            hideFooter: false,
            onSubmit:(payload=>{
                // let's show registration
                tfFormEmbed_Onboarding.close();
                UI_REF_LoginModal.open();
            })
        });
        tfFormEmbed_Onboarding.open();
    }

    function startInstantContextSetup(contextLabel){
        //
        CURR_CMEfy_InstancePayload = {
            owner_cmefy_uid: firebase.auth().currentUser.uid,
            cmefy_context: contextLabel,
            cmefy_persona: "all",
            cmefy_topics: "___reflective-practitioner-cmefy",
            cmefy_mode:"Right now!"
        }
        tfFormInstantContext = createPopup(process.env.TYPEFORM_CMEFY_INSTANTCONTEXT, {
            hidden:{
                uid:firebase.auth().currentUser.uid,
                cmefy_context: CURR_CMEfy_InstancePayload.cmefy_context
            },
            hideFooter: false,
            onSubmit:(payload=>{
                // let's do all the fun on the credits, creating link and then opening up the room introduction modal :)
                firebase.database().ref("typeform_responses/"+payload.response_id).on('value', (snapShot)=>{
                    if (snapShot.val()){
                        firebase.database().ref("typeform_responses/"+payload.response_id).off();
                        var INSTANTContextPacket = snapShot.val();
                        // cmefy_persona, cmefy_topics, shareable_title
                        for (var p in INSTANTContextPacket){
                            CURR_CMEfy_InstancePayload[p] = INSTANTContextPacket[p];
                        }
                        //
                        FB_AuthGetToken(uidToken=>{
                            if(uidToken){
                                //
                                $.ajax({
                                    url: `/v2_5/perform-smart-search?uidToken=${uidToken}&persona=${CURR_CMEfy_InstancePayload.cmefy_persona}&q=${CURR_CMEfy_InstancePayload.cmefy_topics || get_RandomReflectionNudgeQuery("reflective")}`,
                                    dataType: "json",
                                    success: (nudgeResults) => {
                                        tfFormInstantContext.close();
                                        if (nudgeResults.nudges){
                                            CURR_CMEfy_InstancePayload.nudges = nudgeResults.nudges;
                                            CURR_CMEfy_InstancePayload.total_cmes = nudgeResults.nudges.length/2.0; //
                                            $.ajax({
                                                url: "/create-cmefy-record",
                                                type: "POST",
                                                data: {
                                                    uidToken: uidToken,
                                                    cmefyPacket: CURR_CMEfy_InstancePayload
                                                },
                                                dataType: "json",
                                                success: function(creationResult){
                                                    if (creationResult.error){
                                                        ERROR_Alerter(creationResult.error);
                                                        return;
                                                    }
                                                    //
                                                    CURR_CMEfy_InstancePayload.cmefy_id = creationResult.createdRecordID;
                                                    CURR_CMEfy_InstancePayload.cmefy_link = creationResult.cmefy_link;
                                                    //
                                                    moveToCMEfy_StepC();
                                                    //
                                                    FB_LogEvent("created_instantcontextcmes",{
                                                        cmefy_persona: CURR_CMEfy_InstancePayload.cmefy_persona,
                                                        cmefy_context: CURR_CMEfy_InstancePayload.cmefy_context,
                                                        cmefy_cmes: CURR_CMEfy_InstancePayload.total_cmes,
                                                        cmefy_topics: CURR_CMEfy_InstancePayload.cmefy_topics || ["Reflective Nudges only"]
                                                    });
                                                },
                                                error: function(err){
                                                    ERROR_Alerter(err);
                                                    tfFormInstantContext.close();
                                                    console.log(err);
                                                }
                                            });
                                        }
                                    },
                                    error: function(err){
                                        ERROR_Alerter(err);
                                        tfFormInstantContext.close();
                                    }
                                });
                            }else{
                                tfFormInstantContext.close();
                            }
                        })
                    }
                });
            })
        });
        tfFormInstantContext.open();
    }

    function startBetaFeatureIntake(){
        tfFormEmbed_ProfileBetaFeatures = createPopup(process.env.TYPEFORM_CMEFY_BETA_INBOUND, {
            hidden: {uid:firebase.auth().currentUser.uid},
            hideFooter: false,
            onSubmit:(payload=>{
                tfFormEmbed_ProfileBetaFeatures.close();
            })
        })
        tfFormEmbed_ProfileBetaFeatures.open();
    }

    function startCMEfy(){
        if (Stop_IfAccountOutOfLimits()){
            return;
        }
        CURR_CMEfy_InstancePayload = {
            owner_cmefy_uid: firebase.auth().currentUser.uid
        };
        tfFormEmbed_A = createPopup(process.env.TYPEFORM_CMEFY_STEP_A_v2_7_FORMID, {
            hidden:{uid:firebase.auth().currentUser.uid},
            hideFooter: false,
            onSubmit:(payload=>{
                // let's go to step B
                firebase.database().ref("typeform_responses/"+payload.response_id).on('value', (snapShot)=>{
                    if (snapShot.val()){
                        firebase.database().ref("typeform_responses/"+payload.response_id).off();
                        var A = snapShot.val();
                        // cmefy_context, cmefy_mode, cmefy_topics, cmefy_persona
                        for (var p in A){
                            CURR_CMEfy_InstancePayload[p] = A[p];
                        }
                        // v2.7 | force Curr_CMEfy_InstantPayload to have quick as the cmefy_shortcut
                        CURR_CMEfy_InstancePayload.cmefy_shortcut = "CME Credits v2.7 (quick)";
                        //
                        FB_AuthGetToken(uidToken=>{
                            if(uidToken){
                                //
                                switch(CURR_CMEfy_InstancePayload.cmefy_persona){
                                    case "MDs":
                                        CURR_CMEfy_InstancePayload.cmefy_persona = "md";
                                        break;
                                    case "NPs":
                                        CURR_CMEfy_InstancePayload.cmefy_persona = "np";
                                        break;
                                    case "PAs":
                                        CURR_CMEfy_InstancePayload.cmefy_persona = "pa";
                                        break;
                                    case "AAs":
                                        CURR_CMEfy_InstancePayload.cmefy_persona = "aa";
                                        break;
                                    default:
                                        CURR_CMEfy_InstancePayload.cmefy_persona = "all";
                                        break;
                                }
                                //
                                if (CURR_CMEfy_InstancePayload.cmefy_shortcut){
                                    if (CURR_CMEfy_InstancePayload.cmefy_shortcut.toLowerCase().indexOf("(quick)") > -1){
                                        CURR_CMEfy_InstancePayload.cmefy_topics = `___reflective-practitioner-cmefy`;//`"Put an exclamation point on your learning!","Expand your sources to keep up"`
                                    }
                                }
                                if (CURR_CMEfy_InstancePayload.cmefy_ipoc_keyword){
                                    // Kelli -- add ipoc keyword as a default smart tag. You're welcome drummond
                                    CURR_CMEfy_InstancePayload.cmefy_smart_tags = [CURR_CMEfy_InstancePayload.cmefy_ipoc_keyword];
                                }
                                //
                                $.ajax({
                                    url: `/v2_5/perform-smart-search?uidToken=${uidToken}&persona=${CURR_CMEfy_InstancePayload.cmefy_persona}&q=${CURR_CMEfy_InstancePayload.cmefy_topics || get_RandomReflectionNudgeQuery("reflective")}`,
                                    dataType: "json",
                                    success: (nudgeResults) => {
                                        tfFormEmbed_A.close();
                                        if (nudgeResults.nudges){
                                            CURR_CMEfy_InstancePayload.nudges = nudgeResults.nudges;
                                            CURR_CMEfy_InstancePayload.total_cmes = nudgeResults.nudges.length/2.0; //
                                            moveToCMEfy_StepB();
                                        }
                                    },
                                    error: function(err){
                                        ERROR_Alerter(err);
                                        tfFormEmbed_A.close();
                                    }
                                });
                            }else{
                                tfFormEmbed_A.close();
                            }
                        })
                    }
                });
            })
        });
        tfFormEmbed_A.open();
    }

    function moveToCMEfy_StepB(){
        tfFormEmbed_B = createPopup(process.env.TYPEFORM_CMEFY_STEP_B_FORMID, {
            hidden:{
                uid:firebase.auth().currentUser.uid,
                total_cmes: CURR_CMEfy_InstancePayload.total_cmes,
                context: CURR_CMEfy_InstancePayload.cmefy_context,
                mode: CURR_CMEfy_InstancePayload.cmefy_mode
            },
            hideFooter: false,
            onSubmit:(payload=>{
                // let's go to step C
                firebase.database().ref("typeform_responses/"+payload.response_id).on('value', (snapShot)=>{
                    if (snapShot.val()){
                        firebase.database().ref("typeform_responses/"+payload.response_id).off();
                        var B = snapShot.val();
                        // published_on_profile, shareable_description, shareable_title
                        for (var p in B){
                            CURR_CMEfy_InstancePayload[p] = B[p];
                        }
                        // if we have a link pasted, we should be providing this context
                        if (CURR_CMEfy_InstancePayload.cmefy_contextual_url){
                            CURR_CMEfy_InstancePayload.shareable_description = (CURR_CMEfy_InstancePayload.shareable_description||"")+`<a href="${CURR_CMEfy_InstancePayload.cmefy_contextual_url}" class="bold-text red-text" target="_blank">Please read / review this link</a>`
                        }
                        //
                        // we have a result, we need to create a firestore request :)
                        FB_AuthGetToken(uidToken=>{
                            if (uidToken){
                                $.ajax({
                                    url: "/create-cmefy-record",
                                    type: "POST",
                                    data: {
                                        uidToken: uidToken,
                                        cmefyPacket: CURR_CMEfy_InstancePayload
                                    },
                                    dataType: "json",
                                    success: function(creationResult){
                                        if (creationResult.error){
                                            ERROR_Alerter(creationResult.error);
                                            tfFormEmbed_B.close();
                                            return;
                                        }
                                        //
                                        CURR_CMEfy_InstancePayload.cmefy_id = creationResult.createdRecordID;
                                        CURR_CMEfy_InstancePayload.cmefy_link = creationResult.cmefy_link;
                                        //
                                        tfFormEmbed_B.close();
                                        moveToCMEfy_StepC(true);
                                        FB_LogEvent("created_cmefylink",{
                                            cmefy_persona: CURR_CMEfy_InstancePayload.cmefy_persona,
                                            cmefy_context: CURR_CMEfy_InstancePayload.cmefy_context,
                                            cmefy_cmes: CURR_CMEfy_InstancePayload.total_cmes,
                                            cmefy_topics: CURR_CMEfy_InstancePayload.cmefy_topics || ["Reflective Nudges only"]
                                        });
                                    },
                                    error: function(err){
                                        ERROR_Alerter(err);
                                        tfFormEmbed_B.close();
                                        console.log(err);
                                    }
                                });
                            }else{
                                tfFormEmbed_B.close();
                            }
                        });
                    }
                });
            })
        });
        tfFormEmbed_B.open();
    }

    var FLAG_LaunchHostingLink = false;
    function moveToCMEfy_StepC(refreshOnClose){
        tfFormEmbed_C = createPopup(process.env.TYPEFORM_CMEFY_STEP_C_v2_7_FORMID, {
            hidden:{
                uid:firebase.auth().currentUser.uid,
                cmefy_link: CURR_CMEfy_InstancePayload.cmefy_link,
                context: CURR_CMEfy_InstancePayload.cmefy_context,
                mode: CURR_CMEfy_InstancePayload.cmefy_mode
            },
            hideFooter: false,
            onSubmit:( payload => {
                tfFormEmbed_C.close();
                if (refreshOnClose){
                    console.log(CURR_CMEfy_InstancePayload);
                    App_LoadAndShowUserData();
                }
                if (FLAG_LaunchHostingLink){
                    performUITask('cmefied_launch_currentinstantround_host');
                }
            })
        });
        tfFormEmbed_C.open();
    }

    function show_PaymentPriceTF(){
        tfFormEmbed_PaidLink = createPopup(process.env.TYPEFORM_CMEFY_PAIDLINK_FORMID, {
            hidden:{
                context: CURR_CMEfy_InstancePayload.cmefy_context,
                total_cmes: CURR_CMEfy_InstancePayload.total_cmes
            },
            hideFooter: false,
            onSubmit:(payload=>{
                // let's go to step B
                firebase.database().ref("typeform_responses/"+payload.response_id).on('value', (snapShot)=>{
                    if (snapShot.val()){
                        firebase.database().ref("typeform_responses/"+payload.response_id).off();
                        var A = snapShot.val();
                        // cmefy_paypacket_amount
                        console.log("Payment raw amount is = " + A["cmefy_paypacket_amount"]);
                        if (A["cmefy_paypacket_amount"]){
                            var paymentAmountCents = Number(A["cmefy_paypacket_amount"])*100;
                            console.log("Payment amount is = " + paymentAmountCents);
                            if (paymentAmountCents > 0){
                                firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(CURR_CMEfyIdInEditMode).update({
                                    cmefy_paypacket: {
                                        amount: paymentAmountCents,
                                        activated_on: (new Date()).getTime()
                                    }
                                }).then(docSnapshot=>{
                                    FB_LogEvent('cmefy_paidlink_activated',{
                                        cmefy_id:CURR_CMEfyIdInEditMode,
                                        amount: paymentAmountCents
                                    });
                                    M.toast({html:`Payment activated for ${CURR_CMEfy_InstancePayload.shareable_title}!`});
                                    CURR_CMEfyIdInEditMode = false;
                                });
                            }else if (paymentAmountCents === 0){
                                firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(CURR_CMEfyIdInEditMode).update({
                                    cmefy_paypacket: firebase.firestore.FieldValue.delete()
                                }).then(docSnapshot=>{
                                    FB_LogEvent('cmefy_paidlink_removed',{
                                        cmefy_id:CURR_CMEfyIdInEditMode
                                    });
                                    M.toast({html:`Payment settings removed for ${CURR_CMEfy_InstancePayload.shareable_title}!`});
                                    CURR_CMEfyIdInEditMode = false;
                                });
                            }
                        }else if (A["cmefy_paypacket_amount"] == 0){
                            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(CURR_CMEfyIdInEditMode).update({
                                cmefy_paypacket: firebase.firestore.FieldValue.delete()
                            }).then(docSnapshot=>{
                                FB_LogEvent('cmefy_paidlink_removed',{
                                    cmefy_id:CURR_CMEfyIdInEditMode
                                });
                                M.toast({html:`Payment settings removed for ${CURR_CMEfy_InstancePayload.shareable_title}!`});
                                CURR_CMEfyIdInEditMode = false;
                            });
                        }
                        //
                        tfFormEmbed_PaidLink.close();
                    }
                });
            })
        });
        tfFormEmbed_PaidLink.open();
    }

    // anything after this will bark on subscription :)
    function Stop_IfAccountOutOfLimits(){
        if (!UserAccount_CheckStatus()){
            if (CURR_UserAccountState.state === "subscribed"){
                // we need to upsell
                UI_REF_Upsell_Modal.open();
                return true;
            }else{
                // we push to subscription
                window.location.href = "/subscribe";
                return true;
            }
        }
        return false;
    }

    function performUITask(task){
        if (task.indexOf("cmefied_record_show_link::") === 0){
            var docId = task.split("::")[1].trim();
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                CURR_CMEfy_InstancePayload = docSnapshot.data();
                moveToCMEfy_StepC(false);
            });
        }

        if (task.indexOf("cmefied_record_show_embed::") === 0){
            var docId = task.split("::")[1].trim();
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                var CMEFY_Instance = docSnapshot.data();
                $('#cmefy-embed-plain').text(`Reflect and earn CME here ${CMEFY_Instance.cmefy_link}`)
                $('#cmefy-embed-image-white').text(`<!-- CMEfy Link: Copy & paste in your webpage HTML --><a href="https://cmefy.com/moment?id=${docId}" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/cmefy-platform.appspot.com/o/earn-cme-btn-white-bgd.png?alt=media" alt="Earn CMEs with CMEfy"></a>`)
                $('#cmefy-embed-image-dark').text(`<!-- CMEfy Link: Copy & paste in your webpage HTML --><a href="https://cmefy.com/moment?id=${docId}" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/cmefy-platform.appspot.com/o/earn-cme-btn-dark-bgd-color.png?alt=media" alt="Earn CMEs with CMEfy"></a>`)
                //
                UI_REF_LinkEmbedViewModal.open();
    
            });
            //alert(docId);
        }

        if (task.indexOf("cmefied_record_show_link_for_bribri::") === 0){
            var docId = task.split("cmefied_record_show_link_for_bribri::")[1].trim();
            window.open("/moment-redesign?id="+docId,"CMEfy");
        }

        if (task.indexOf("cmefied_record_open_link::")=== 0){
            var docId = task.split("cmefied_record_open_link::")[1].trim();
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                CURR_CMEfy_InstancePayload = docSnapshot.data();
                window.location.href ="/moment?preview=true&id="+docId;
                //window.open("/moment?id="+docId,"CMEfy");
            });
        }


        if (task.indexOf("cmefied_record_edit_moment::") === 0){
            var docId = task.split("cmefied_record_edit_moment::")[1].trim();
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                UI_initiateCMEfyEditor(docId, docSnapshot.data());
            });
        }

        if (task.indexOf("cmefied_record_show_insights::") === 0){
            var docId = task.split("cmefied_record_show_insights::")[1].trim();
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                CURR_CMEfy_InstancePayload = docSnapshot.data();
                $('.insights_title').html(CURR_CMEfy_InstancePayload.shareable_title);
                //
                FB_AuthGetToken(uidToken=>{
                    if (uidToken){
                        $.ajax({
                            url: `/fetch-cmefied-impactstats`,
                            type: "POST",
                            data: {
                                uidToken: uidToken,
                                cmefy_source: docId
                            },
                            dataType: "json",
                            success: (statsPacket) => {
                                console.log(statsPacket);
                                //
                                UI_Process_ImpactStats(statsPacket)
                            },
                            error: function(err){
                                ERROR_Alerter(err);
                            }
                        });

                    }else{
                        ERROR_Alerter("Please re-login -- your access token is not valid.");
                    }
                });
            });
        }

        if (task.indexOf("cmefied_record_delete::") === 0){
            var docId = task.split("::")[1].trim();
            //
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).delete().then(docSnapshot=>{
                M.Toast.dismissAll();
                App_LoadAndShowUserData();
            });
        }

        if (task.indexOf("cmefied_record_delete_createnew::") === 0){
            var docId = task.split("::")[1].trim();
            //
            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).delete().then(docSnapshot=>{
                M.Toast.dismissAll();
                SHOW_LinkCreator = true; // we show the link creator
                App_LoadAndShowUserData();
            });
        }

        //
        if (task.indexOf("cmefied_paid_link::") === 0){

            var docId = task.split("cmefied_paid_link::")[1].trim();
            // let's check the payments hook
            firebase.firestore().collection("stripe_connect").doc(firebase.auth().currentUser.uid).get().then(stripeSnapShot=>{
                if (stripeSnapShot.exists){
                    if (stripeSnapShot.data()["details_submitted"] === true){
                        firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(docId).get().then(docSnapshot=>{
                            CURR_CMEfy_InstancePayload = docSnapshot.data();
                            CURR_CMEfyIdInEditMode = docId;
                            // we should see if there's a payment load
                            console.log(CURR_CMEfy_InstancePayload);
                            if (CURR_CMEfy_InstancePayload.cmefy_paypacket){
                                if (confirm("You're currently charging $" + CURR_CMEfy_InstancePayload.cmefy_paypacket.amount/100 + " for this co-learning moment. Would you like to change this price?")){
                                    show_PaymentPriceTF();
                                }
                            }else{
                                show_PaymentPriceTF();
                            }
                        });
                    }else{
                        M.toast({html:`To create paid links, securely connect your bank account in your <a class="ui-action-btn" data-task="show-payouts">payouts profile</a>.`});
                    }
                }else{
                    M.toast({html:`To create paid links, securely connect your bank account in your <a class="ui-action-btn" data-task="show-payouts">payouts profile</a>.`});
                }
            });
        }

        
        switch (task){
            case "show-login-clinician":
                showFirebaseAuthView(true);
                break;
            case "show-login-educator":
                showFirebaseAuthView(false);
                break;
            case "launch-onboarding":
                startOnboarding();
                break;
            case "smart-subscription-router":
                if (CURR_UserAccountState.is_clinician){
                    window.location.href = '/subscription-plans';
                }else{
                    window.location.href = '/subscribe';
                }
                break;
            case "show-profile":
                console.log("CMEfy Handle: " + CURR_UserProfile.cmefy_handle);
                if (!CURR_UserProfile.cmefy_handle){
                    // we show the payment hook :)
                    // TODO: Put subscription hook here
                    $('#profile_save_btn').html('Save');
                }else{
                    $('#profile_save_btn').html('Save');
                }
                UI_REF_ProfileModal.open();
                break;
            case "show-settings":
                if (!CURR_UserProfile.cmefy_handle){
                    // we show the payment hook :)
                    // TODO: Put subscription hook here
                    $('#profile_save_btn').html('Create');
                }else{
                    $('#profile_save_btn').html('Save');
                }
                UI_REF_Settings_Modal.open();
                break;
            case "show-payouts":
                UI_REF_Payouts_Modal.open();
                break;
            case "beta-capture":
                UI_REF_ProfileModal.close();
                startBetaFeatureIntake();
                break;
            case "start-cmefy":
                startCMEfy();
                break;
            case "quick-cmefy":
                CURR_CMEfy_InstancePayload = {
                    cmefy_link: "https://nudge.ooo/wqg",
                    cmefy_context: "Quick CME",
                    cmefy_mode: "Right now"
                }
                moveToCMEfy_StepC(false);
                break;
            case "cmefied_record_delete_oldest_link_prompt":
                var oldest_cmefied_record = Get_Oldest_CMEfyCard(CURR_CMEfiedData_arr);
                if (oldest_cmefied_record){
                    return M.toast({html: `<span>Delete <b>${oldest_cmefied_record.shareable_title}</b> and create a new link?</span><button class="btn-flat toast-action ui-action-btn" data-task="cmefied_record_delete_createnew::${oldest_cmefied_record.id}">Delete</button><button class="btn-flat toast-action ui-action-btn" data-task="logout-no">Cancel</button>`});
                }
                break;
            case "delete-cmefymoment":
                if (CURR_CMEfyIdInEditMode){
                    UI_REF_MomentEditorModal.close();
                    //
                    return M.toast({html: '<span>Would you like to delete this CMEfy link?</span><button class="btn-flat toast-action ui-action-btn" data-task="confirm-delete-cmefymoment">Yes</button><button class="btn-flat toast-action ui-action-btn" data-task="logout-no">No</button>'})
                }
                break;
            case "confirm-delete-cmefymoment":
                return performUITask(`cmefied_record_delete::${CURR_CMEfyIdInEditMode}`);
                break;
            case "save-cmefymoment-edits":
                if (CURR_CMEfyIdInEditMode){
                    // we must save
                    var cmefyUpdatePacket = {
                        shareable_description: $('#edit_shareabledescription').val(),
                        shareable_title: $('#edit_shareabletitle').val(),
                        cmefy_smart_tags: M.Chips.getInstance(document.querySelector('#edit_cmefysmarttags')).chipsData.map(x=>x.tag)
                    };
                    FB_AuthGetToken(uidToken=>{
                        if (uidToken){
                            $.ajax({
                                url: "/update-cmefy-record",
                                type: "POST",
                                data: {
                                    uidToken: uidToken,
                                    cmefyId: CURR_CMEfyIdInEditMode,
                                    cmefyUpdatePacket: cmefyUpdatePacket
                                },
                                dataType: "json",
                                success: function(updateResult){
                                    if (updateResult.error){
                                        ERROR_Alerter(updateResult.error);
                                        return;
                                    }
                                    //
                                    CURR_CMEfyIdInEditMode = false;
                                    $('#edit_shareabletitle').val('');
                                    $('#edit_shareabledescription').val('');
                                    M.updateTextFields();
                                    //
                                    M.toast({html:"Successfully updated!"});
                                    //
                                    UI_REF_MomentEditorModal.close();
                                    App_LoadAndShowUserData();
                                    //
                                },
                                error: function(err){
                                    ERROR_Alerter(err);
                                    console.log(err);
                                }
                            });
                        }else{
                            ERROR_Alerter("Token expired, please re-login before updating this record! [For security!]");
                            UI_REF_MomentEditorModal.close();
                        }
                    });
                }
                break;
            case "save-profile-changes":
                var profilePacket = {
                    uid: firebase.auth().currentUser.uid,
                    tags: ["adaptrack","Anesthesiology"],// from the type of user & NPI check we are able to generate
                    display_name: $('#display_name').val(),
                    short_bio: $('#short_bio').val(),
                    notification_email: $('#notification_email').val(),
                    share_impactdata: $('#share_learningimpact').is(":checked"),
                    is_profile_public:$('#share_profilepublicly').is(":checked"),
                    public_profile_url: $('#public_profile_url').val(),
                    cmefy_handle: $('#catalyst_handle').val().trim() // is used for the earnc.me title
                };
                //
                $('#profile_url_key').val(`https://cmefy.com/u/${profilePacket.cmefy_handle}`)
                //
                console.log(profilePacket);
                // cmefy_handle should also have a UID key
                console.log(profilePacket);
                console.log(CURR_UserProfile);
                if (CURR_UserProfile.uid){
                    firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).update(profilePacket).then(()=>{
                        CURR_UserProfile = profilePacket;
                    }).catch(ex=>{
                        console.log(ex);
                        alert("User Ignored");
                    });
                }else{
                    firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).set(profilePacket).then(()=>{
                        CURR_UserProfile = profilePacket;
                    }).catch(ex=>{
                        console.log(ex);
                        alert("User Ignored");
                    });
                }
                //
                break;
            case "instant-context-redirect":
                if (REDIR_URL){
                    window.location.href = REDIR_URL;
                }else{
                    window.location.reload();
                }
                break;
            case "launch-instantround":
                firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).get()
                    .then(docSnapshot=>{
                        if (docSnapshot.exists){
                            if (docSnapshot.data()['current_instantround']){
                                // we must take them to the hosting view :), where they can close it! 
                                var cmefy_id_to_check = docSnapshot.data()['current_instantround']['cmefy_id'];
                                firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(cmefy_id_to_check).get().then(cmefied_idCheck=>{
                                    if (cmefied_idCheck.exists){
                                        performUITask('cmefied_launch_currentinstantround_host');
                                    }else{
                                        firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).update({
                                            current_instantround: firebase.firestore.FieldValue.delete()
                                        }); 
                                        InstantRound_StartSetup();
                                    }
                                });
                            }else{
                                return InstantRound_StartSetup();
                            }
                        }else{
                            return InstantRound_StartSetup();
                        }
                    })
                    .catch(err=>{
                        return ERROR_Alerter(err);
                    });
                break;
            case "cmefied_launch_currentinstantround_host":
                // launch the current instant round in the profile ;) 
                InstantRound_LaunchHostingView();
                break;
            case "instantrounds-launch-cmefy-host-link":
                // launch a view that only contains
                if (CURR_InstantRoundRef){
                    window.open("/moment?hostmode=true&id="+CURR_InstantRoundRef["cmefy_id"],"CMEfy");
                }
                break;
            case "instantrounds-confirm-terminate-room":
                M.toast({html: '<span>End this Instant Round?</span><button class="btn-flat toast-action ui-action-btn" data-task="instantrounds-confirm-yes-terminate-room">Yes</button><button class="btn-flat toast-action ui-action-btn" data-task="logout-no">No</button>'})
                break;
            case "instantrounds-confirm-yes-terminate-room":
                InstantRound_TerminateRoom();
                break;
            case "profile-url:copy":
                if (navigator.clipboard){
                    navigator.clipboard.writeText($('#profile_url_key').val());
                    M.toast({html:"Link copied!"});
                }else{
                    M.toast({html:"We can't access your clipboard! Open, text or email it instead."})
                }
                break;
            case "stripe::initiate-connect":
                // start initiation
                FB_AuthGetToken(uidToken=>{
                    if (uidToken){
                        $.ajax({
                            url: `/stripe/fetch-stripeconnect-smartlink?uidToken=${uidToken}`,
                            method: "get",
                            json: true,
                            success: function(linkResult){
                                if (linkResult.url){
                                    if (linkResult.isDashboardLink){
                                        window.open(linkResult.url, "Payments_Dashboard");
                                    }else{
                                        window.location.href = linkResult.url;
                                    }
                                }else{
                                    ERROR_Alerter(linkResult);
                                }
                            },
                            error: function(err){
                                console.log(err);
                                ERROR_Alerter(err);
                            }
                        });
                    }else{
                        ERROR_Alerter("Bad user token! Relogin and try again");
                    }
                });
                break;
            case "confirm-reset-password":
                M.toast({html: '<span>Reset your password?</span><button class="btn-flat toast-action ui-action-btn" data-task="reset-password-yes">Yes</button><button class="btn-flat toast-action ui-action-btn" data-task="logout-no">No</button>'})
                break;
            case "reset-password-yes":
                firebase.auth().sendPasswordResetEmail(firebase.auth().currentUser.email, {url:"https://cmefy.com/?app&toast=Please+re-login!"})
                    .then(() => {
                        // Password reset email sent!
                        M.Toast.dismissAll();
                        M.toast({html:"We just emailed you a Reset Password link to " + firebase.auth().currentUser.email + "<br/>Please log back in with your new password!"});
                        setTimeout(()=>{
                            performUITask('logout-yes');
                        },3600);
                    })
                    .catch((error) => {
                        // ..
                        ERROR_Alerter(error);
                    });
                break;
            case "open-support-beacon":
                Beacon_initAndShow(()=>{
                    console.log("here");
                    Beacon('open');
                });
                try{UI_REF_ProfileModal.close();}catch(ex){}
                break;
            case "confirm-logout":
                M.toast({html: '<span>Logout?</span><button class="btn-flat toast-action ui-action-btn" data-task="logout-yes">Yes</button><button class="btn-flat toast-action ui-action-btn" data-task="logout-no">No</button>'})
                break;
            case "logout-yes":
                firebase.auth().signOut();
                window.location.reload();
                break;
            case "logout-no":
                M.Toast.dismissAll();
                break;
            case "unlink-google-account":
                firebase.auth().currentUser.unlink("google.com").then(() => {
                    // Auth provider unlinked from account
                    M.toast({html:"Google Account has been unlinked. Please re-login / create a password to continue.", classes:'amber accent-3 white-text flow-text'})
                    setTimeout(()=>{
                        firebase.auth().signOut();
                        window.location.reload();
                    }, 4100);
                    console.log("Google unlinked!")
                    // ...
                  }).catch((error) => {
                    // An error happened
                    alert("Error in unlinking Google account")
                    // ...
                  });
                break;
            default:
                console.log("No op");
                break;
        }

    }

    //JSKJM
    
    //

    function Get_Oldest_CMEfyCard(cmefied_records_arr){
        var arr_bu = [...cmefied_records_arr];
        arr_bu = arr_bu.filter(x=>{
            if (x.cmefy_paypacket){
                return false;
            }
            return true;
        });
        arr_bu.sort(function(a, b) {
            return a.createdOn - b.createdOn;
        });
        //
        return arr_bu[0];
    }

    function UserAccount_CheckStatus(){
        if (CURR_UserAccountState.state === "subscribed"){
            if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_Clinician){
                return false;
            }
            if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician-plus" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_ClinicianPlus){
                return false;
            }
            return true;
        }else if (CURR_UserAccountState.state === "subscribe-trial-expired"){
            return false;
        }else{
            if (CURR_UserAccountState.state === "activated" && CURR_CMEFIED_RECORDS_Count>0){
                return false;
            }
        }
        if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_Clinician){
            return false;
        }
        if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician-plus" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_ClinicianPlus){
            return false;
        }
        return true;
    }

    var CURR_CMEfyIdInEditMode = false;
    function UI_initiateCMEfyEditor(docId, cmefyPacket){
        CURR_CMEfyIdInEditMode = docId;
        // let's load the editor
        console.log(cmefyPacket);
        //
        $('#cmefy_editor_title_and_ipoc').html("titled <b>" + cmefyPacket.shareable_title + (cmefyPacket.cmefy_ipoc_keyword?" (key concept: <span class='teal-text'>"+cmefyPacket.cmefy_ipoc_keyword+"</span>)":"") + "</b>")
        //
        $('#edit_shareabledescription').val(cmefyPacket.shareable_description);
        $('#edit_shareabletitle').val(cmefyPacket.shareable_title);
        if (cmefyPacket.cmefy_smart_tags){
            
        }
        //
        M.Chips.init(document.querySelectorAll('#edit_cmefysmarttags'),{
            placeholder: 'Enter a tag',
            secondaryPlaceholder: '+Tag',
            data: cmefyPacket.cmefy_smart_tags?cmefyPacket.cmefy_smart_tags.map(x=>{return{tag:x}}):[]
        });
        M.updateTextFields();
        //
        UI_REF_MomentEditorModal.open();
    }

    function STRIPE_CheckConnectIfFlagged(stripeFlag){
        if (stripeFlag){
            // let's review with the system
            FB_AuthGetToken(uidToken=>{
                if (uidToken){
                    $.ajax({
                        url: `/stripe/check-stripeconnect-setup?uidToken=${uidToken}`,
                        method: "get",
                        json: true,
                        success: function(setupResult){
                            console.log(setupResult);
                            if (setupResult.stripe_connect_setup){
                                M.toast({html:"✅&nbsp;&nbsp;Congrats! Your payments account is linked."});
                            }
                        },
                        error: function(err){
                            console.log(err);
                            ERROR_Alerter(err);
                        }
                    });
                }else{
                    ERROR_Alerter("Bad user token! Relogin and try again");
                }
            });
        }
    }

    function get_RandomReflectionNudgeQuery(category){
        var randomQueries = [];
        switch (category){
            case "co-learning":
                randomQueries = ['Do you realize the powerful knowledge base that exists within your network of medical friends and colleagues'];
                break;
            default:
                console.log("getting standard reflection hook!");
                randomQueries = ['___reflective-practitioner-cmefy'];//['Have you ever stopped to journal a reflection during your work day?','Now visualize yourself quickly dictating or writing that thought down here and sharing it with your team or colleagues']
                break;
        }
        return randomQueries[Math.floor(Math.random()*randomQueries.length)];
    }

    function InstantRound_StartSetup(){
        CURR_CMEfy_InstancePayload = {
            owner_cmefy_uid: firebase.auth().currentUser.uid,
            cmefy_context: "Instant Round",
            cmefy_mode:"Right now!",
            cmefy_topics: "___reflective-practitioner-cmefy",
            cmefy_persona: "all",
            shareable_description: "Created on " + Moment().toString(),
        }
        console.log(firebase.auth().currentUser.email);
        tfFormInstantRound = createPopup(process.env.TYPEFORM_CMEFY_INSTANTROUNDS, {
            hidden:{instantround_email:firebase.auth().currentUser.email},
            hideFooter: false,
            onSubmit:(payload=>{
                // let's do all the fun on the credits, creating link and then opening up the room introduction modal :)
                /* 
                    1. create the instant round
                    2. find the nudges
                    3. update the cmefy payload & 
                    4. the profile to flag this is the current link
                */
                console.log(payload.responseId);
                firebase.database().ref("typeform_responses/"+payload.response_id).on('value', (snapShot)=>{
                    if (snapShot.val()){
                        firebase.database().ref("typeform_responses/"+payload.response_id).off();
                        var MGR = snapShot.val();
                        // shareable_title & some other incremental fun
                        for (var p in MGR){
                            CURR_CMEfy_InstancePayload[p] = MGR[p];
                        }
                        //
                        FB_AuthGetToken(uidToken=>{
                            if(uidToken){
                                // 1. create the instant round
                                InstantRound_CreateAndCallback(uidToken, callbackInstantRoundPacket=>{
                                    if (callbackInstantRoundPacket.error){
                                        tfFormInstantRound.close();
                                        return ERROR_Alerter(callbackInstantRoundPacket.error);
                                    }
                                    // 2. find the nudges
                                    $.ajax({
                                        url: `/v2_5/perform-smart-search?uidToken=${uidToken}&persona=${CURR_CMEfy_InstancePayload.cmefy_persona}&q=${CURR_CMEfy_InstancePayload.cmefy_topics || get_RandomReflectionNudgeQuery("co-learning")}`,
                                        dataType: "json",
                                        success: (nudgeResults) => {
                                            if (nudgeResults.nudges){
                                                CURR_CMEfy_InstancePayload.nudges = nudgeResults.nudges;
                                                CURR_CMEfy_InstancePayload.total_cmes = nudgeResults.nudges.length/2.0; //
                                                // instant round specific items are appended to this payload :) 
                                                CURR_CMEfy_InstancePayload.instantround_packet = callbackInstantRoundPacket;
                                                // 3. update the cmefy payload
                                                $.ajax({
                                                    url: "/create-cmefy-record",
                                                    type: "POST",
                                                    data: {
                                                        uidToken: uidToken,
                                                        cmefyPacket: CURR_CMEfy_InstancePayload
                                                    },
                                                    dataType: "json",
                                                    success: function(creationResult){
                                                        if (creationResult.error){
                                                            ERROR_Alerter(creationResult.error);
                                                            return;
                                                        }
                                                        // we have our created CMEfy Link, let's create the instant round now
                                                        CURR_CMEfy_InstancePayload.cmefy_id = creationResult.createdRecordID;
                                                        CURR_CMEfy_InstancePayload.cmefy_link = creationResult.cmefy_link;
                                                        // 4. update the profile now
                                                        firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).update({current_instantround:CURR_CMEfy_InstancePayload}).then(()=>{
                                                            FLAG_LaunchHostingLink = callbackInstantRoundPacket.hostRoomUrl;
                                                            moveToCMEfy_StepC(true);
                                                        }).catch(ex=>{
                                                            return ERROR_Alerter(ex);
                                                        });
                                                        tfFormInstantRound.close();
                                                    },
                                                    error: function(err){
                                                        ERROR_Alerter(err);
                                                        tfFormInstantRound.close();
                                                        console.log(err);
                                                    }
                                                });
                                            }else{
                                                tfFormInstantRound.close();
                                            }
                                        },
                                        error: function(err){
                                            ERROR_Alerter(err);
                                            tfFormInstantRound.close();
                                        }
                                    });
                                });
                                
                            }else{
                                tfFormInstantRound.close();
                            }
                        })
                    }
                });
            })
        });
        tfFormInstantRound.open();
    }

    function InstantRound_CreateAndCallback(uidToken, callback){
        //
        $.ajax({
            url: "/api/start-instant-round",
            type: "POST",
            data: {
                uidToken: uidToken
            },
            dataType: "json",
            success: function(creationResult){
                if (creationResult.error){
                    return callback({error:creationResult.error});
                }
                return callback(creationResult);
            },
            error: function(err){
                console.log(err);
                callback({error:err});
                ERROR_Alerter(err);
            }
        });
    }

    var CURR_InstantRoundRef = false;
    function InstantRound_LaunchHostingView(){
        // 
        firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).get().then((snapshotValue)=>{
            if (snapshotValue.exists){
                if (snapshotValue.data()["current_instantround"]){
                    //
                    CURR_InstantRoundRef = snapshotValue.data()["current_instantround"];
                    //
                    // TODO: Figure out Earn CME for hosts & termination of room (and corresponding view in the Momentv2 based on the roomUrl availability for 'Instant Round')
                    //
                    $('#instantround_shareable_title').html(snapshotValue.data()["current_instantround"]["shareable_title"])
                    $('#instantround_earncme_link_alt').html(snapshotValue.data()["current_instantround"]["cmefy_link"]);
                    $('#instantround_earncme_link').html(snapshotValue.data()["current_instantround"]["cmefy_link"]);
                    //
                    $('#whereby_iframe_holder').html(`<iframe src="${snapshotValue.data()["current_instantround"]["instantround_packet"]["hostRoomUrl"]}&embed&logo=on&screenshare=on&displayName=${CURR_UserProfile.display_name}" style="width:100%; height:calc(100% - 5px);" allowfullscreen frameborder="0" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>`)
                    UI_REF_WherebyEmbedModal.open();
                }
            }
        }).catch(ex=>{
            return ERROR_Alerter(ex);
        });
        //
        
    }

    function InstantRound_TerminateRoom(){
        //
        FB_AuthGetToken(uidToken=>{
            if (uidToken){
                if (CURR_InstantRoundRef){
                    $.ajax({
                        url: "/api/terminate-instant-round",
                        type: "POST",
                        data: {
                            uidToken: uidToken,
                            meetingId: CURR_InstantRoundRef.instantround_packet.meetingId,
                        },
                        dataType: "json",
                        success: function(creationResult){
                            // no error checker, we need to make sure this works itself out from clean up
                            firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).update({
                                current_instantround: firebase.firestore.FieldValue.delete()
                            });                            
                            firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").doc(CURR_InstantRoundRef.cmefy_id).update({
                                'instantround_packet.roomUrl': "turned-off"
                            });
                            //
                            UI_REF_WherebyEmbedModal.close();
                        },
                        error: function(err){
                            console.log(err);
                            ERROR_Alerter(err);
                        }
                    });    
                }        
            }
        });
    }

    const MAX_ReflectionInsights = 25;
    function UI_Process_ImpactStats(impactStats){
        //
        if (impactStats.error){
            ERROR_Alerter(impactStats.error);
            return;
        }
        if (impactStats.resultPacket.length === 0){
            M.toast({html:"No learners have reflected on the nudges from this CMEfied moment - no impact data to show!", classes:'amber accent-3 white-text flow-text'})
            return;
        }
        // load impact stats :)
        var totalReflectioons = impactStats.resultPacket.length;
        var totalCMEs = totalReflectioons * 0.5;
        var totalMinutesSaved = 59*totalCMEs;//59 minutes saved per CME
        var learnerReflectionHash = {}; // learner <> reflections hash
        var totalLearners = 0; // total unique learners
        var nudgeReflectionHash = {}; // nudge <> reflection count hash
        var reflections = []; // actual reflections
        for (var i = 0;i<Math.min(impactStats.resultPacket.length, MAX_ReflectionInsights);i++){
            var reflection = impactStats.resultPacket[i];
            //
            if (learnerReflectionHash[reflection.uid_md5]){
                learnerReflectionHash[reflection.uid_md5] = 0;
            }
            learnerReflectionHash[reflection.uid_md5]++;
            //
            if (!nudgeReflectionHash[reflection.nudge_headline]){
                nudgeReflectionHash[reflection.nudge_headline] = [];
            }
            //
            if (reflection.reflection !== "not-shared"){
                reflections.push(reflection.reflection);
                reflection.reflectionMoment = Moment(reflection.reflected_on).fromNow();
                reflection.is_question = false;
                reflection.is_claim = false;
                if (reflection.reflection.indexOf("?") >= 0){
                    reflection.is_question = true;
                }else if (reflection.reflection.indexOf("I ")>= 0){
                    reflection.is_claim = true;
                }
                (nudgeReflectionHash[reflection.nudge_headline]).push(reflection);
            };
        }
        //
        var REFLECTIVENUDGES_NudgeHeadlines = {think:"What did you learn?", apply: "What will you do?"};
        function getNudgeStatsHeadlineForActualNudgeHeadline(nudge_str){
            if (CURR_CMEfy_InstancePayload.cmefy_topics === "___reflective-practitioner-cmefy"){
                for (var i = 0;CURR_CMEfy_InstancePayload.nudges.length;i++){
                    if (CURR_CMEfy_InstancePayload.nudges[i].nudge_headline === nudge_str){
                        return CURR_CMEfy_InstancePayload.nudges[i].nudge_category === "reflective-think"?REFLECTIVENUDGES_NudgeHeadlines.think:REFLECTIVENUDGES_NudgeHeadlines.apply;
                    }
                }
            }
            return nudge_str;
        }
        // let's handlebar it
        $('#mi_reflections_holder').empty();
        for (var n in nudgeReflectionHash){
            $('#mi_reflections_holder').append(H_miReflectionTemplate({nudge_headline: getNudgeStatsHeadlineForActualNudgeHeadline(n), reflections: nudgeReflectionHash[n]}));
        }
        $('.anonymized-legend').remove();//remove instructions :)
        //v2.7 no-need: $('#mi_reflections_holder').parent().append(`<p class="anonymized-legend super-fine-print right-align">↑ Anonymized Learner Reflections: <span class="red-text bold-text">Questions in red</span> | <span class="blue-text bold-text">Remarks / ideas in blue</span></p>`);
        //
        totalLearners = Object.keys(learnerReflectionHash).length;
        //
        $('#mi_total_cmes').html(totalCMEs);
        $('#mi_total_learners').html(totalLearners);
        $('#mi_total_timesaved').html(totalMinutesSaved);
        /*
            cme: 0.5
            cmefy_source: "jSRptX8"
            nudge_headline: "Triple check the drug interactions with COVID."
            reflected_on: 1624991356158
            reflection: "This is great"
            uid_md5: "ac87c850054417a8fa474449c372d085"
            _id: "60db667d150666000abfc00c"
         */
        //
        M.Toast.dismissAll();
        UI_REF_MomentInsightsModal.open();
    }

    function App_LoadAndShowProfileData(callback){
        //
        // Profile loading
        firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).get().then((userSnapshot)=>{
            CURR_UserProfile = userSnapshot.data() || {};
            //
            FB_LogEvent("user_launched_app", {user_marker: firebase.auth().currentUser.uid});
            //
            if (!CURR_UserProfile.cmefy_handle){
                // no handle, we remove the micrograndround
                $('#micrograndrounds_btn').hide();
            }else{
                $('#micrograndrounds_btn').hide();
                $('#profile_url_key').val(`https://cmefy.com/u/${CURR_UserProfile.cmefy_handle}`)
            }
            //
            $('.my_email_address').html(firebase.auth().currentUser.email);
            $('#display_name').val(CURR_UserProfile.display_name);
            //
            if (CURR_UserProfile.display_name){
                $('#greeting_name').html(CURR_UserProfile.display_name)
                //
            }
            $('.subscription_tier').html(CURR_UserAccountState.cmefy_account_type || "not subscribed yet");                            
            $('#catalyst_handle').val(CURR_UserProfile.cmefy_handle);
            $('#public_profile_url').val(CURR_UserProfile.public_profile_url);
            $('#short_bio').val(CURR_UserProfile.short_bio);
            M.textareaAutoResize(document.querySelector('#short_bio'));
            $('#notification_email').val(CURR_UserProfile.notification_email);
            if (!CURR_UserProfile.notification_email && CURR_UserProfile.cmefy_handle){
                //M.toast({html:"Looks like your notification email for inbound speaking requests is missing. Please open the profile and specify a valid email address!"});
            }
            //
            if (CURR_UserProfile.is_profile_public){
                $('#share_profilepublicly').attr("checked", "checked");
            }else{
                $('#share_profilepublicly').removeAttr("checked");
            }
            if (CURR_UserProfile.share_impactdata){
                $('#share_learningimpact').attr("checked", "checked");
            }else{
                $('#share_learningimpact').removeAttr("checked");
            }
            //
            if (!CURR_UserProfile.inbox){
                $('#inbox_alert_btn').hide();
                $('#profile_inbox').remove();
            }else{
                $('#inbox_alert_btn').addClass('animated headShake').show();
                $('#cmefy_profile_inbox').empty();
                for (var i = 0;i<CURR_UserProfile.inbox.length; i++){
                    $('#cmefy_profile_inbox').append(`<div class="card-panel">${CURR_UserProfile.inbox[i]} <a class="blue-grey-text text-lighten-3 ui-action-btn right" data-task="delete-inbox-item::${i}">DELETE</a></div>`)
                }
            };
            M.updateTextFields();
            // we don't have a CMEfy Handle
            if (!CURR_UserProfile.cmefy_handle){
                // don't force show this! performUITask("show-profile");
                //$('#profile_save_btn').html('Create');
            }else{
                //$('#profile_save_btn').html('Save');
            }
            // payments flow checker :)
            firebase.firestore().collection("stripe_connect").doc(firebase.auth().currentUser.uid).get().then(paymentSnap=>{
                if (paymentSnap.data()){
                    if (paymentSnap.data()["details_submitted"] == true && paymentSnap.data()["charges_enabled"] == true){
                        $('#connect-btn_txt').html('Manage Payouts &amp; More');
                    }else{
                        $('#connect-btn_txt').html('Securely Link Bank Account');
                    }
                }
            });
            //
            callback();
        }).catch(ex=>{
            CURR_UserProfile = {};
            callback();
        });
    }

    var Beacon_inited = false;
    function Beacon_initAndShow(callback){
        if (!Beacon_inited) Beacon('init','60a77568-902f-477c-b2fd-2a725f4ed284');
        if (Beacon_inited){
            if (callback) return callback();
        }
        Beacon('on', 'ready', () => {
            Beacon_inited = true;
            if (callback) return callback();
        });
    }

    function Beacon_initAndShowWithDelay(){
        if (HIDE_HelpBeacon){
            return;
        }
        if (HELP_Timer) clearTimeout(HELP_Timer);
        HELP_Timer = setTimeout(()=>{
            Beacon_initAndShow();
        }, 1800);

    }

    const BEACON_defaultArticleID = '6197b7ea2b380503dfe06b96';//617348069ccf62287e5eecd7
    function Beacon_presentArticle(articleId){
        if (!articleId){
            articleId = BEACON_defaultArticleID;
            return Beacon('show-message', '40be4f5f-e071-4cd3-a38d-311274d36439');
        }
        Beacon('article', articleId);
    }

    function Beacon_Identify(){
        if (firebase.auth().currentUser.email){
            Beacon('identify', {
                email: firebase.auth().currentUser.email,
                name: firebase.auth().currentUser.displayName || firebase.auth().currentUser.email.split("@")[0],
                signed_up_on: Moment(firebase.auth().currentUser.metadata.creationTime).format("YYYY-MM-DD"),
                last_signin_on: Moment(firebase.auth().currentUser.metadata.lastSignInTime).format("YYYY-MM-DD"),
                persona: CURR_UserAccountState.cmefy_account_type,
                subscription_status: CURR_UserAccountState.state,
                subscription_stripe: CURR_UserAccountState.stripe?CURR_UserAccountState.stripe:false,
                cmefy_links_total: CURR_CMEFIED_RECORDS_Count,
                cmefy_links_paid: CURR_CMEFIED_RECORDS_Paid_Count,
                cmefy_links_free: CURR_CMEFIED_RECORDS_Count-CURR_CMEFIED_RECORDS_Paid_Count 
            });
            //
            Beacon('prefill',{
                email: firebase.auth().currentUser.email,
                name: firebase.auth().currentUser.displayName || firebase.auth().currentUser.email.split("@")[0]
            })
        }
    }

    var CURR_CMEFIED_RECORDS_Count = 0;
    var CURR_CMEFIED_RECORDS_Paid_Count = 0;
    var UI_REF_CMEfiedLinksTabulatorTable;
    var CURR_CMEfiedData_arr;
    var FLAG_Table_GroupByContext = false;
    var FLAG_Table_ShowSearchFilter = false;
    function App_LoadAndShowUserData(){
        firebase.firestore().collection("cmefied").doc(firebase.auth().currentUser.uid).collection("records").orderBy('createdOn','desc').get().then((querySnapshot)=>{
            //
            var totalEvents = 0;
            var totalPaidLinks = 0;
            var cmefied_topics = [];
            var cmefied_records_arr = [];
            querySnapshot.forEach((doc)=>{
                totalEvents++;
                var docObject = {id:doc.id};
                for (var i in doc.data()){docObject[i] = doc.data()[i]};
                docObject.cmefy_topics = docObject.cmefy_topics || "";
                docObject.cmefy_calc_paymode = "Free";
                if (docObject.cmefy_paypacket){
                    totalPaidLinks++;
                    docObject.cmefy_paypacket.amount_dollars = docObject.cmefy_paypacket.amount/100.0;
                    docObject.cmefy_calc_paymode = "Paid ($" + docObject.cmefy_paypacket.amount_dollars + ")";
                }
                cmefied_topics = cmefied_topics.concat(docObject.cmefy_topics.toLowerCase().split(",").map(x=>x.trim()));
                cmefied_records_arr.push(docObject);
            });
            //
            CURR_CMEFIED_RECORDS_Count = totalEvents;
            CURR_CMEFIED_RECORDS_Paid_Count = totalPaidLinks;
            // do an account check :) 
            var allowMoreActions_bool = Account_CheckAllowedActivity_FLAG();                                
            // basic_impact_stats = total co-learning moments hosted & topics snapshot :)
            if (allowMoreActions_bool){
                App_CheckAndPreloadContextualQuickFlow();
            }
            
            // UI Setup for Table
            FLAG_Table_ShowSearchFilter = false;
            FLAG_Table_GroupByContext = false;
            v2_5_ShowTable(cmefied_records_arr, {
                showSearchFilter:FLAG_Table_ShowSearchFilter,
                groupByContext: FLAG_Table_GroupByContext,
            });
            //
            Beacon_Identify();
            FB_UpdateUserProperties("current_links", totalEvents);
            FB_UpdateUserProperties("current_paid_links", totalPaidLinks);
            //
            cmefied_topics = remove_duplicates(cmefied_topics);
            if (totalEvents === 0){
                // we don't have any CMEfied links:
                $('#cmefied-intro-view').show();
                $('#cmefied-links-table').hide();
                $('#cmefied-links-pagination').hide();
                $('#cmefied-table-filters-holder').hide();
                $('#cmefied-links').hide();
                $('#profile_impact').hide();
                if (SHOW_LinkCreator){
                    startCMEfy();
                }else{
                    Beacon_initAndShow(()=>{
                        Beacon_presentArticle();
                    });
                }
            }else{
                //
                Beacon_initAndShowWithDelay();
                $('#cmefied-intro-view').hide();
                $('#cmefied-links-table').show();
                $('#cmefied-links-pagination').show();
                $('#cmefied-table-filters-holder').show();
                $('#cmefied-links').show();
                $('#basic_impact_stats').html(`<b>${totalEvents}</b> Total Co-learning Moments Hosted<br/><b>Topics</b>: ${cmefied_topics.join(", ")}` );
                $('#profile_impact').show();
                if (SHOW_LinkCreator){                    
                    startCMEfy();
                }
            }
            //
        });
    }

    
    function v2_5_ShowTable(dataArr, visualFlags){
        if (dataArr){
            CURR_CMEfiedData_arr = dataArr;
        }
        //custom header filter
        var dateFilterEditor = function(cell, onRendered, success, cancel, editorParams){
            var container = $("<span></span>")
            //create and style input
            var start = $("<input type='date' placeholder='Start' class='browser-default fine-print'/>");
            var end = $("<input type='date' placeholder='End' class='browser-default fine-print'/>");

            container.append(start).append(end);

            var inputs = $("input", container);


            inputs.css({
                "padding":"4px",
                "width":"50%",
                "box-sizing":"border-box",
            })
            .val(cell.getValue());

            function buildDateString(){
                return {
                    start:start.val(),
                    end:end.val(),
                };
            }

            //submit new value on blur
            inputs.on("change blur", function(e){
                success(buildDateString());
            });

            //submit new value on enter
            inputs.on("keydown", function(e){
                if(e.keyCode == 13){
                    success(buildDateString());
                }

                if(e.keyCode == 27){
                    cancel();
                }
            });

            return container[0];
        }

        //custom filter function
        function dateFilterFunction(headerValue, rawRowValue, rowData, filterParams){
            //headerValue - the value of the header filter element
            //rowValue - the value of the column in this row
            //rowData - the data for the row being filtered
            //filterParams - params object passed to the headerFilterFuncParams property
            var rowValue = Moment(rawRowValue).format("MM/DD/YYYY");

            var format = filterParams.format || "MM/DD/YYYY";
            var start = Moment(headerValue.start);
            var end = Moment(headerValue.end);
            var value = Moment(rowValue, format)
            console.log(value.toISOString());
            if(rowValue){
                if(start.isValid()){
                    if(end.isValid()){
                        return value >= start && value <= end;
                    }else{
                        return value >= start;
                    }
                }else{
                    if(end.isValid()){
                        return value <= end;
                    }
                }
            }

            return false; //must return a boolean, true if it passes the filter.
        }
        console.log(CURR_CMEfiedData_arr);
        var lm_ColTitle = "Learning Moment";
        if (CURR_CMEfiedData_arr.length > 10){
            lm_ColTitle = "Learning Moment" + ` (${CURR_CMEfiedData_arr.length})`;
        }
        var columnArr_config = [];
        if (visualFlags.showSearchFilter){
            columnArr_config = [ //Define Table Columns
                {responsive:0, title:lm_ColTitle, field:"shareable_title", formatter:(cell, formatterParams, onRendered)=>{  
                    var titlePhrase = `${cell.getRow().getData().shareable_title}`;//+(cell.getRow().getData().cmefy_ipoc_keyword?`<span class="badge chip super-fine-print light right">${cell.getRow().getData().cmefy_ipoc_keyword}</span>`:``);
                    //titlePhrase+= `<br/><span class="sub-fine-print blue-grey white-text copy-contents-btn">${cell.getRow().getData().cmefy_link}</span>`;
                    return titlePhrase;
                },headerSort:false,headerFilter:visualFlags.showSearchFilter, clickMenu: [
                    { label: "Share CMEfy Link", action: (e, cell) => { performUITask(`cmefied_record_show_link::${cell.getRow().getData().id}`);}},
                    { label: "Link Impact", action: (e, cell) => { performUITask(`cmefied_record_show_insights::${cell.getRow().getData().id}`);}},
                    { label: "Payout Settings", action: (e, cell) => { performUITask(`cmefied_paid_link::${cell.getRow().getData().id}`);}},
                    { label: "Edit CMEfy Link", action: (e, cell) => {performUITask(`cmefied_record_edit_moment::${cell.getRow().getData().id}`);}}
                ]},
                {title:"Share", field:"cmefy_link",headerSort:false, formatter:(cell, formatterParams, onRendered)=>{
                    var cPhrase= ``;
                    cPhrase += '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_show_link::'+cell.getRow().getData().id+'"><i class="material-icons black-text" style="font-size:21px; margin-top:0px; margin-right:5px;">ios_share</i></a>';
                    cPhrase += `<span class="sub-fine-print blue-grey white-text copy-contents-btn">${cell.getRow().getData().cmefy_link}</span>`;
                    return cPhrase                    
                }},
                {title:"Context", field:"cmefy_context", headerSort:false,headerFilter:visualFlags.showSearchFilter, formatter:(cell, formatterParams, onRendered)=>{
                    var cPhrase = cell.getRow().getData().cmefy_context;
                    if (cell.getRow().getData().cmefy_paypacket?.amount_dollars){
                        cPhrase += '&nbsp;<a class="cmefy-item-table-action-link teal-text text-accent-4 ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'">$'+cell.getRow().getData().cmefy_paypacket.amount_dollars+'</a>';
                    }else{
                        //cPhrase += '&nbsp;<a class="cmefy-item-table-action-link blue-grey-text text-accent-4 ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'"><i class="material-icons right">price_change</i></a>';
                    }
                    return cPhrase                    
                }},
                {title:"Paid / Free", field:"cmefy_calc_paymode",headerSort:false,headerFilter:visualFlags.showSearchFilter},
                {title:"Smart Tags", field:"cmefy_smart_tags",headerSort:false,headerFilter:visualFlags.showSearchFilter, formatter:(cell, formatterParams, onRendered)=>{
                    return (cell.getRow().getData().cmefy_smart_tags||[]).join(", ");
                }},
                {title:"Creation Date", width:250, field:"createdOn", align:"center", formatter:(cell, formatterParams, onRendered)=>{
                    return new Date(cell.getRow().getData().createdOn).toLocaleDateString('en-US');                   
                }, headerSort:false, headerFilter:dateFilterEditor, headerFilterFunc:dateFilterFunction},
                {responsive:0, title:"More Actions", headerHozAlign:'right', minWidth:144, hozAlign:'right', headerSort:false, formatter:(cell, formatterParams, onRendered)=>{
                    var menuPhrase = ''
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_show_insights::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone black-text">analytics</i></a>'
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">local_atm</i></a>'
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_edit_moment::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone black-text">tune</i></a>'
                        //'<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_delete::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">delete</i></a>'
                        //+ '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_open_link::'+cell.getRow().getData().id+'"><i class="material-icons black-text">preview</i></a>'
                        
                    return menuPhrase;//'<a style="cursor:pointer;"><i class="material-icons tiny blue-grey-text">menu</i></a>';
                }},
            ]
        }else{
            columnArr_config = [ //Define Table Columns
                {responsive:0, title:lm_ColTitle, field:"shareable_title", formatter:(cell, formatterParams, onRendered)=>{  
                    var titlePhrase = `${cell.getRow().getData().shareable_title}`;//+(cell.getRow().getData().cmefy_ipoc_keyword?`<span class="badge chip super-fine-print light right">${cell.getRow().getData().cmefy_ipoc_keyword}</span>`:``);
                    //titlePhrase+= `<br/><span class="sub-fine-print blue-grey white-text copy-contents-btn">${cell.getRow().getData().cmefy_link}</span>`;
                    return titlePhrase;
                },headerSort:false,headerFilter:visualFlags.showSearchFilter, clickMenu: [
                    { label: "Share CMEfy Link", action: (e, cell) => { performUITask(`cmefied_record_show_link::${cell.getRow().getData().id}`);}},
                    { label: "Link Impact", action: (e, cell) => { performUITask(`cmefied_record_show_insights::${cell.getRow().getData().id}`);}},
                    { label: "Payout Settings", action: (e, cell) => { performUITask(`cmefied_paid_link::${cell.getRow().getData().id}`);}},
                    { label: "Edit CMEfy Link", action: (e, cell) => {performUITask(`cmefied_record_edit_moment::${cell.getRow().getData().id}`);}}
                ]},
                {title:"Share", field:"cmefy_link",headerSort:false, formatter:(cell, formatterParams, onRendered)=>{
                    var cPhrase= ``;
                    cPhrase += '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_show_link::'+cell.getRow().getData().id+'"><i class="material-icons black-text" style="font-size:21px; margin-top:0px;  margin-right:5px;">ios_share</i></a>';
                    cPhrase += `<span class="sub-fine-print blue-grey white-text copy-contents-btn">${cell.getRow().getData().cmefy_link}</span>`;
                    return cPhrase                    
                }},
                {title:"Context", field:"cmefy_context",headerSort:false,headerFilter:visualFlags.showSearchFilter, formatter:(cell, formatterParams, onRendered)=>{
                    var cPhrase = cell.getRow().getData().cmefy_context;
                    if (cell.getRow().getData().cmefy_paypacket?.amount_dollars){
                        cPhrase += '&nbsp;<a class="cmefy-item-table-action-link teal-text text-accent-4 ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'">$'+cell.getRow().getData().cmefy_paypacket.amount_dollars+'</a>';
                    }else{
                        //cPhrase += '&nbsp;<a class="cmefy-item-table-action-link blue-grey-text text-accent-4 ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'"><i class="material-icons right">price_change</i></a>';
                    }
                    return cPhrase                    
                }},
                // {responsive:3, title:"Created on", formatter:(cell, formatterParams, onRendered)=>{
                //     return (new Date(cell.getRow().getData().createdOn).toLocaleDateString());
                // }, sorter:(a, b, aRow, bRow, column, dir, sorterParams)=>{
                //     return aRow.getData().createdOn-bRow.getData().createdOn;
                // }},
                {responsive:0, title:"More Actions", headerHozAlign:'right', minWidth:144, hozAlign:'right', headerSort:false, formatter:(cell, formatterParams, onRendered)=>{
                    var menuPhrase = ''
                        //+ '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_show_link_for_bribri::'+cell.getRow().getData().id+'"><i class="material-icons black-text">launch</i></a>'
                        //+ '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_open_link::'+cell.getRow().getData().id+'"><i class="material-icons black-text">preview</i></a>'
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_show_insights::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone black-text">analytics</i></a>'
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">local_atm</i></a>'
                        + '<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_edit_moment::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone black-text">tune</i></a>'
                        //'<a class="cmefy-item-table-action-link waves-effect ui-action-btn" data-task="cmefied_record_delete::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">delete</i></a>'
                    
                    return menuPhrase;//'<a style="cursor:pointer;"><i class="material-icons tiny blue-grey-text">menu</i></a>';
                }},
                
                // {responsive:0, width:100, formatter:(cell, formatterParams, onRendered)=>{
                //         return ''+                        
                //         '<a class="cmefy-item-action-btn waves- waves-effect waves-teal ui-action-btn" data-task="cmefied_record_show_link::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">info</i></a>'+
                //         //'<a class="cmefy-item-action-btn waves- waves-effect waves-teal ui-action-btn" data-task="cmefied_record_open_link::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">preview</i></a>'+
                //         (cell.getRow().getData().cmefy_paypacket?.amount_dollars?'<a class="cmefy-item-action-btn waves-effect waves-teal ui-action-btn bold-text teal-text" style="line-height:1em;" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'">$'+cell.getRow().getData().cmefy_paypacket.amount_dollars+'</a>':'<a class="cmefy-item-action-btn waves-effect waves-teal ui-action-btn" data-task="cmefied_paid_link::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">paid</i></a>')+
                //         '<a class="cmefy-item-action-btn waves- waves-effect waves-teal ui-action-btn" data-task="cmefied_record_show_insights::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone black-text">insights</i></a>'+
                //         '<a class="cmefy-item-action-btn waves- waves-effect waves-teal ui-action-btn" data-task="cmefied_record_edit_moment::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">edit</i></a>'+                
                //         '<a class="cmefy-item-action-btn waves- waves-effect waves-teal ui-action-btn" data-task="cmefied_record_delete::'+cell.getRow().getData().id+'"><i class="material-icons-two-tone">delete</i></a>';
                //     }, hozAlign:"right", headerSort:false
                // },
                // {responsive:3, title:"Created on", formatter:(cell, formatterParams, onRendered)=>{
                //     return (new Date(cell.getRow().getData().createdOn).toLocaleDateString());
                // }, sorter:(a, b, aRow, bRow, column, dir, sorterParams)=>{
                //     return aRow.getData().createdOn-bRow.getData().createdOn;
                // }},
                

            ]
        }
        $('#cmefied-links-pagination').empty();
        UI_REF_CMEfiedLinksTabulatorTable = new Tabulator("#cmefied-links-table", {
                maxHeight:"100%",
                data:CURR_CMEfiedData_arr, //assign data to table
                layout:"fitDataStretch",
                selectable:false,
                movableColumns: true,
                pagination: !visualFlags.groupByContext,
                paginationSize: 10,
                paginationElement: document.querySelector('#cmefied-links-pagination'),
                paginationButtonCount: 3,
                responsiveLayout: false,
                columns:columnArr_config,
                groupBy:visualFlags.groupByContext?"cmefy_context":null,
        });
        UI_REF_CMEfiedLinksTabulatorTable.on("tableBuilt", ()=>{
           $('#cmefied-table-filters-holder').removeClass('hiddendiv')
        })
        UI_REF_CMEfiedLinksTabulatorTable.on("renderComplete", ()=>{
            $('#cmefied-table-filters-holder').removeClass('hiddendiv')
        })
    }
    function UI_Table_ToggleGroupByContext(doGroupBool){
        if (doGroupBool){
            FLAG_Table_GroupByContext = true;
        }else{
            FLAG_Table_GroupByContext = false;
        }
        v2_5_ShowTable(false, {
            showSearchFilter:FLAG_Table_ShowSearchFilter,
            groupByContext: FLAG_Table_GroupByContext,
        })
    }
    function UI_Table_ToggleSearchFilter(doFilterSearch){
        if (doFilterSearch){
            FLAG_Table_ShowSearchFilter = true;
        }else{
            FLAG_Table_ShowSearchFilter = false;
        }
        v2_5_ShowTable(false, {
            showSearchFilter:FLAG_Table_ShowSearchFilter,
            groupByContext: FLAG_Table_GroupByContext,
        })
    }

    function App_CheckAndPreloadContextualQuickFlow(){
        console.log("PRE_CONTEXT_App = " + PRE_CONTEXT_App);
        if (PRE_CONTEXT_App){
            switch (PRE_CONTEXT_App){
                case "instantround":
                    PRE_CONTEXT_App = false;
                    return performUITask('launch-instantround');
                    break;
                case "olivehelps":
                    PRE_CONTEXT_App = false;
                    return startInstantContextSetup("Olive Helps");
                    break;
                case "docsocial":
                    PRE_CONTEXT_App = false;
                    return startInstantContextSetup("Doc.Social");
                    break;
                default:
                    // noop
                    break;
            }
        }
    }

    function remove_duplicates(arr) {
        var obj = {};
        var ret_arr = [];
        for (var i = 0; i < arr.length; i++) {
            obj[arr[i]] = true;
        }
        for (var key in obj) {
            ret_arr.push(key);
        }
        return ret_arr;
    }

    // AUTH Master
    function AUTH_FirebaseInitAndAuthCheck(){
        var firebaseConfig = {
            apiKey: "AIzaSyAGwxNtQFoExfy5h6QwsiXtRufkecGdKjo",
            authDomain: "cmefy-platform.firebaseapp.com",
            projectId: "cmefy-platform",
            storageBucket: "cmefy-platform.appspot.com",
            messagingSenderId: "920328618854",
            appId: "1:920328618854:web:09e10fa0f1e4ae21fdfea7",
            measurementId: "G-D6L8YYD6QE"
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        //
        // Auth Checking Eventbased :)
        firebase.auth().onAuthStateChanged(function(user) {
            AUTH_CheckAndProceed(user);
        });
    }

    function AUTH_CheckAndProceed(user){
        if (user) {
            //
            console.log(user.providerData);
            $('#google-login-flag').hide();
            for (var i = 0;i<user.providerData.length;i++){
                var prov = user.providerData[i].providerId;
                if (prov === "google.com"){
                    $('#google-login-flag').show();
                }
            }
            // set the userid
            firebase.analytics().setUserId(firebase.auth().currentUser.uid);
            // check & show auth view
            UI_REF_LoginModal.close();
            if (getUrlParameter('logout')){ // search a nudge by text
                firebase.auth().signOut();
                history.pushState({},"CMEfy | Catalyzing Clinician Co-learning","/")
                window.location.reload();
            }
            //
            FB_AuthGetToken(uidToken=>{
                if (uidToken){
                    $.ajax({
                        url: `/v2_5/verify-cmefy-token?uidToken=${uidToken}`,
                        dataType: "json",
                        success: (userValid) => {
                            CURR_UserAccountState = userValid;
                            // lets set the visuals
                            if (CURR_UserAccountState.state === 'subscribed'){
                                $('#subscription-nudge-btn').remove();
                            }
                            //
                            console.log(`user is signed in: ${user.uid}`);
                            console.log(userValid);
                            if(userValid.allowed){
                                //
                                showAuthView();
                                UI_BindBtnTasks_Clicks();
                                App_LoadAndShowProfileData(()=>{
                                    App_LoadAndShowUserData();
                                });
                                //
                                console.log(`user is valid`);
                            }else{
                                window.location.href = `/waitlist?e=${user.email}&u=${user.uid}&n=${user.displayName}`;
                            }
                        },
                        error: function(err){
                            ERROR_Alerter(err);
                            firebase.auth().signOut();
                        }
                    });
                }
            });
        }else{
            if (!getUrlParameter('app')){
                window.location.href = 'https://about.cmefy.com'
                return;
            }
            showUnauthView();
            //
            if (getUrlParameter('adaptrack')){
                showFirebaseAuthView(true);
                return;
            }
            //
            showFirebaseAuthView(false);
        }
    }

    const MAX_TierLinks_Clinician = 10;
    const MAX_TierLinks_ClinicianPlus = 25;

    function Account_CheckAllowedActivity_FLAG(){
        if (CURR_UserAccountState.cmefy_account_type === "clinician" || CURR_UserAccountState.cmefy_account_type === "clinician-plus"){
            $('.free-links-counter').html((CURR_UserAccountState.cmefy_account_type === "clinician"?MAX_TierLinks_Clinician:MAX_TierLinks_ClinicianPlus)-(CURR_CMEFIED_RECORDS_Count-CURR_CMEFIED_RECORDS_Paid_Count));
            $('#clinician-starter-tier-p').hide(); // was show
        }else{
            $('#clinician-starter-tier-p').remove();
        }
        var standardDirective = `You can <a class="bold-text ui-action-btn" data-task="cmefied_record_delete_oldest_link_prompt">click here to delete an older link</a> or <a class="bold-text" href="/subscribe">upgrade to another plan to unlock more active links</a>`;
        // disallow if clinician tier has 10 or more FREE links
        if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_Clinician){
            ANNOUNCEMENT_ShowAlert(`<p class="fine-print light">→ This tier allows a total of ${Math.min(CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count, MAX_TierLinks_Clinician)} active free CMEfy links (paid links don't count!).<br/>${standardDirective}</p>`);
            return false;
        }
        if (CURR_UserAccountState.allowed === true && CURR_UserAccountState.cmefy_account_type === "clinician-plus" && (CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count) >= MAX_TierLinks_ClinicianPlus){
            ANNOUNCEMENT_ShowAlert(`<p class="fine-print light">→ This tier allows a total of ${Math.min(CURR_CMEFIED_RECORDS_Count - CURR_CMEFIED_RECORDS_Paid_Count, MAX_TierLinks_ClinicianPlus)} active free CMEfy links (paid links don't count!).<br/>${standardDirective}</p>`);
            return false;
        }
        if (CURR_UserAccountState.allowed === false){
            ANNOUNCEMENT_ShowAlert(`<p class="fine-print light">Subscription inactive / expired. Please <a href="/subscribe">review and subscribe to a plan here</a>.</p>`);
            return false;
        }
        return true;
    }

    function ANNOUNCEMENT_ShowAlert(divContents){
        $('.cmefy-master-announcement-bar').html(divContents).css("display","block");
    }

    function FB_LogEvent(event, packet){
        firebase.analytics().logEvent(event, packet);
    }

    function FB_UpdateUserProperties(prop, val){
        var p = {};
        p[prop] = val;
        try{
            firebase.analytics().setUserProperties(p);
        }catch(ex){
            //noop
        }
    }

    function showFirebaseAuthView(isClinicianMode){
        if (!ui){
            ui = new firebaseui.auth.AuthUI(firebase.auth());
        }
        var uiAuthConfig_clinician = {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    return false;
                },
                uiShown: function() {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'redirect',
            signInOptions: [
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    recaptchaParameters: {
                        type: 'image', // 'audio'
                        size: 'normal', // 'invisible' or 'compact'
                        badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
                    },
                    whitelistedCountries: ['US','CA'],
                    requireDisplayName: true
                }
            ],
            // Terms of service url.
            tosUrl: 'https://app.termly.io/document/terms-and-conditions/423ec307-4d7a-4fc4-9e61-eb641c1b15fb',
            // Privacy policy url.
            privacyPolicyUrl: 'https://app.termly.io/document/privacy-policy/4dd77b97-74c7-4b4f-9f49-d02129b587d2'
        };
        var uiAuthConfig_admin = {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    return false;
                },
                uiShown: function() {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: true,
                },
                firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ],
            // Terms of service url.
            tosUrl: 'https://app.termly.io/document/terms-and-conditions/423ec307-4d7a-4fc4-9e61-eb641c1b15fb',
            // Privacy policy url.
            privacyPolicyUrl: 'https://app.termly.io/document/privacy-policy/4dd77b97-74c7-4b4f-9f49-d02129b587d2'
        };
        //
        if (isClinicianMode){
            $('#login-directive').hide();
            ui.start('#firebaseui-auth-container', uiAuthConfig_clinician);
        }else{
            $('#login-directive').show();
            ui.start('#firebaseui-auth-container', uiAuthConfig_admin);
        }
    }

    function showAuthView(){
        $('#main-preloader').hide();
        //
        $('.auth-view').show();
        $('.unauth-view').hide();
        // let's do state management :)
        PRE_CONTEXT_App = getUrlParameter('app') || false;
        REDIR_URL = getUrlParameter('redir_url') || false;
        SHOW_LinkCreator = getUrlParameter('newflow')? true : false;
        //
        if (getUrlParameter('subscriptionSuccessful')){
            HIDE_HelpBeacon = true;
            DELIGHTED_ShowNPS();
        }
        // stripe management
        STRIPE_CheckConnectIfFlagged(getUrlParameter('stripe'));
        // remove url parameters from the url
        console.log(window.location.href)
        history.pushState({},"CMEfy","/"+(window.location.href.split("/").splice(-1,1)[0].split("?")[0]))
    }
    function showUnauthView(){
        $('#main-preloader').hide();
        $('.auth-view').hide();
        $('.unauth-view').show();
    }

    function ERROR_Alerter(errObj){
        if (errObj){
            console.log(errObj);
            if (errObj.error){
                alert(JSON.stringify(errObj.error));
            }else{
                alert(JSON.stringify(errObj));
            }
        }else{
            alert("There was an error in your connection, please refresh this page.");
        }
    }

    function navigateToApp(){
        window.location.href = "/app";
    }

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    function animateCSS(element, animationName, callback) {
        const node = document.querySelector(element)
        node.classList.add('animated', animationName)

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName)
            node.removeEventListener('animationend', handleAnimationEnd)

            if (typeof callback === 'function') callback()
        }

        node.addEventListener('animationend', handleAnimationEnd)
    }

    function FB_AuthGetToken(callback){
        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
            // Send token to your backend via HTTPS
            return callback(idToken);
            // ...
        }).catch(function(error) {
            // Handle error
            return callback(false);
        });
    }

    function DELIGHTED_ShowNPS(){
        delighted.survey({
            email: firebase.auth().currentUser.email || "generic.cmefy.user@adaptrack.com", 
            name: firebase.auth().currentUser.displayName||"Anonymous CMEfy User"
        });
    }
})();